<template>
	<div class="fill-height d-flex flex-column">
		<AppBar :to-page="toPage" :color="config.color" />
		<v-app-bar dense :color="config.color" flat>
			<v-app-bar-title class="flex text-center font-weight-black">{{
				t("title")
			}}</v-app-bar-title>
		</v-app-bar>
		<div class="fill-height">
			<keep-alive>
				<router-view />
			</keep-alive>
		</div>
		<FooterSlider />
	</div>
</template>

<script>
import AppBar from "@/components/AppBar";
import FooterSlider from "@/components/FooterSlider";
import { mapGetters } from "vuex";
export default {
	components: {
		AppBar,
		FooterSlider
	},
	data: () => ({
		config: {
			color: "primary100"
		}
	}),
	computed: {
		...mapGetters("service", ["getBasePath", "existAirport", "isArrival"]),
		toPage: vm => {
			if (
				vm.$route.query.utm_source === "travelers" &&
				vm.$route.name === "Pick up & Drop Off"
			)
				return "/?token=" + vm.$route.query.token;
			return vm.getBasePath(vm.back);
		},
		back() {
			if (this.$route.name === "Pickup shedule" && !this.existAirport)
				return "/reservation";
			if (this.$route.name === "Passenger" && this.isArrival)
				return "/reservation/flight";
			switch (this.$route.name) {
				case "Pick up & Drop Off":
					return "/";
				case "Flight Info":
					return "/reservation";
				case "Pickup shedule":
					return "/reservation/flight";
				case "Passenger":
					return "/reservation/pschedule";
				default:
					return "/";
			}
		}
	},
	methods: {
		t(key, options = {}) {
			return this.$t(`global.header.${key}`, options);
		}
	}
};
</script>
