<template>
	<div>
		<v-slider
			v-show="routePickup"
			:color="config.sliderColor"
			:track-color="config.trackColor"
			thumb-label
			readonly
			:value="slider[0]"
			:class="config.sliderClass"
		></v-slider>
		<v-slider
			v-if="routeFlightInfo && existAirport"
			:color="config.sliderColor"
			:track-color="config.trackColor"
			readonly
			:value="slider[1]"
			:class="config.sliderClass"
		></v-slider>
		<v-slider
			v-if="routeShedule && !isArrival"
			:color="config.sliderColor"
			:track-color="config.trackColor"
			readonly
			:value="slider[2]"
			:class="config.sliderClass"
		></v-slider>
		<v-slider
			v-if="routePassenger"
			:color="config.sliderColor"
			:track-color="config.trackColor"
			readonly
			:value="slider[3]"
			:class="config.sliderClass"
		></v-slider>
		<v-bottom-navigation
			disa
			grow
			background-color="#FDF1E5"
			v-model="value"
		>
			<v-btn
				class="v-btn--active"
				:ripple="false"
				value="1"
				v-html="t('pickupAndDropoff')"
			>
			</v-btn>
			<v-btn
				v-if="existAirport"
				:class="activeClass(routeFlightInfo)"
				:ripple="false"
				value="2"
				v-html="t('flightInfo')"
			></v-btn>
			<v-btn
				v-if="!isArrival"
				:class="activeClass(routeShedule)"
				:ripple="false"
				value="3"
				v-html="t('pickupSchedule')"
			></v-btn>
			<v-btn
				:class="activeClass(routePassenger)"
				:ripple="false"
				value="4"
				v-html="t('passengers')"
			></v-btn>
		</v-bottom-navigation>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	data: () => ({
		config: {
			trackColor: "#FCE4CC",
			sliderColor: "secondary",
			sliderClass: "slider-bottom mb-n9"
		},
		value: "1"
	}),
	computed: {
		...mapGetters("service", ["existAirport", "isArrival"]),
		slider: vm => {
			if (!vm.existAirport) return [15, 0, 50, 85];
			if (vm.isArrival) return [15, 50, 0, 85];
			return [12, 37, 63, 88];
		},
		routePickup() {
			return true;
		},
		routeFlightInfo() {
			return (
				(this.routePickup && this.$route.name === "Flight Info") ||
				this.$route.name === "Pickup shedule" ||
				this.$route.name === "Passenger"
			);
		},
		routeShedule() {
			return (
				(this.routeFlightInfo &&
					this.$route.name === "Pickup shedule") ||
				this.$route.name === "Passenger"
			);
		},
		routePassenger() {
			return this.routeShedule && this.$route.name === "Passenger";
		}
	},
	methods: {
		activeClass(active) {
			return `${active ? "v-btn--active" : ""}`;
		},
		t(key, options = {}) {
			return this.$t(`global.footer.${key}`, options);
		}
	}
};
</script>
<style lang="scss" scoped>
.slider-bottom {
	position: absolute;
	z-index: 5;
	bottom: 55px;
	left: 0;
	right: 0;
}

::v-deep .v-item-group.v-bottom-navigation .v-btn {
	max-width: 100%;
}
::v-deep .theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
	opacity: 0;
}
::v-deep .theme--light.v-btn:hover::before {
	opacity: 0;
}
::v-deep .theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
	color: rgba(0, 0, 0, 0.6) !important;
}
</style>
